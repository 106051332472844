import styles from "./GlassButton.module.css";

export const GlassButton = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div className={`${className} ${styles.btn}`} {...rest}>
      {children}
    </div>
  );
};
