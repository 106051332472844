import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import iconMouseUrl from "../assets/icon-mouse.svg";
import logoWhiteUrl from "../assets/logo-white.svg";
import { GlassButton } from "../components/GlassButton";
import { GlassCard } from "../components/GlassCard";
import styles from "./index.module.css";
import {
  Currency,
  PricingPlan,
  getPlans,
  labelsInfo,
  labelsOrder,
} from "./pricing-plans";

const LandingPageRender = () => {
  const [plans, setPlans] = useState<PricingPlan[]>([]);
  const [currency, setCurrency] = useState<Currency | null>(null);

  const fetchCurrency = async (): Promise<Currency> => {
    return "USD";
  };

  useEffect(() => {
    (async () => {
      const newCurrency = await fetchCurrency();
      setCurrency(newCurrency);
    })();
  }, []);

  useEffect(() => {
    if (currency) {
      setPlans(getPlans(currency));
    }
  }, [currency]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img className={styles.logo} src={logoWhiteUrl} />
        <div className={styles.navLinks}>
          <a href="#pricing">pricing</a>
          <a href="#contact">contact us</a>
          <a
            href="/terms-and-conditions.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms &<br />
            conditions
          </a>
          <a
            href="/privacy-policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy
            <br />
            policy
          </a>
          <a
            href="/cancellations-and-refunds-policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            cancellations &
            <br />
            refunds policy
          </a>

          <Link
            to="https://app.periscope.sh"
            style={{
              textDecoration: "none",
            }}
          >
            <GlassButton
              style={{
                marginLeft: "1.5em",
              }}
            >
              Login
            </GlassButton>
          </Link>
        </div>
      </div>
      <div className={styles.infoPage}>
        <GlassCard>
          <div className={styles.infoCard}>
            <p
              style={{
                fontWeight: 500,
              }}
            >
              <span className="text-primary">API healthchecks</span> as a<br />
              service
            </p>
            <p
              style={{
                marginTop: "1.5em",
              }}
            >
              We check your APIs every 'n' seconds.
            </p>
            <p>
              We ping you on Slack/Email in case any of your APIs fails the
              healthcheck.
            </p>
            <p>
              You can use our dashboard to check the history of healthchecks.
            </p>
            <p>Simple enough?</p>
          </div>
        </GlassCard>
        <div className={styles.infoPageScroll}>
          <img src={iconMouseUrl} />
          <p>scroll</p>
        </div>
      </div>
      <div id="pricing" className={styles.pricingPage}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GlassCard
            style={{
              width: "100%",
              maxWidth: "750px",
            }}
          >
            <div className={styles.pricingCard}>
              <div className={styles.pricingCardTableHeader}>
                <div
                  style={{
                    flex: 1,
                  }}
                />
                {plans.map((p) => (
                  <div
                    key={p.name}
                    style={{
                      flex: 1,
                    }}
                  >
                    <p>{p.name}</p>
                  </div>
                ))}
              </div>
              {labelsOrder.map((label) => {
                const labelInfo = labelsInfo[label];
                return (
                  <div key={label}>
                    <div
                      style={{
                        flex: 1,
                        padding: "0.75em 0.5em",
                      }}
                    >
                      <p>{labelInfo.title}</p>
                      {!!labelInfo.subtitle && (
                        <p
                          style={{
                            fontSize: "0.8em",
                          }}
                        >
                          {labelInfo.subtitle}
                        </p>
                      )}
                    </div>
                    {plans.map((p) => (
                      <div
                        key={p.name}
                        style={{
                          flex: 1,
                          borderLeft: "1px solid #466BEF80",
                          padding: "0.75em 0.5em",
                        }}
                      >
                        <p>
                          {(p as unknown as { [key: string]: string })[label]}
                        </p>
                      </div>
                    ))}
                  </div>
                );
              })}
              <div className={styles.pricingCardTableActionButtons}>
                <div
                  style={{
                    flex: 1,
                  }}
                />
                {plans.map((p) => (
                  <div
                    key={p.name}
                    style={{
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        padding: "0 1em",
                      }}
                    >
                      <GlassButton
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          if (p.actionButton.link) {
                            window.open(p.actionButton.link, "_blank");
                          } else {
                            toast("Coming soon!", {
                              duration: 1000,
                            });
                          }
                        }}
                      >
                        {p.actionButton.label}
                      </GlassButton>
                    </div>
                  </div>
                ))}
              </div>
              <p
                style={{
                  marginTop: "2em",
                  marginBottom: "1em",
                  fontSize: "0.9em",
                  padding: "0 3em",
                }}
              >
                Note: the payment is upfront every month. Healthchecks will be
                disabled after 24 hours of non-payment of subscription fees. No
                refunds are applicable.
                <br />
              </p>
              <p
                style={{
                  marginBottom: "1.5em",
                  fontSize: "0.9em",
                  padding: "0 3em",
                }}
              >
                For more information, please read our{" "}
                <a
                  href="/terms-and-conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
                {" and "}
                <a
                  href="/cancellations-and-refunds-policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cancellations and Refunds Policy
                </a>
                .
              </p>
            </div>
          </GlassCard>
          <GlassCard
            style={{
              width: "100%",
              maxWidth: "500px",
              marginTop: "10em",
            }}
          >
            <div
              id="contact"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "0.5em 2em",
              }}
            >
              <p
                style={{
                  fontSize: "1.1em",

                  alignSelf: "center",
                }}
              >
                <span className="text-primary">Contact Us</span>
              </p>
              <p>
                Proprietor Name:
                <br />
                Siddharth Venu
              </p>
              <p>
                Registered Address:
                <br />
                G1, #4/608, V.O.C. Street, Desk No.181
                <br />
                Perungudi, OMR
                <br />
                Chennai, Tamil Nadu, India - 600041
              </p>
              <p>
                Phone:
                <br />
                <a href="tel:+919790468714">+91 97904 68714</a>
              </p>
              <p>
                Email:
                <br />
                <a href="mailto:admiral@periscope.sh">admiral@periscope.sh</a>
              </p>
            </div>
          </GlassCard>
          <div className={styles.pageFooter}>
            <p>
              Copyright © 2024{" "}
              <a
                href="https://www.linkedin.com/in/sidvenu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Siddharth Venu
              </a>
              . All Rights Reserved.
            </p>
            <p>
              <a
                href="/terms-and-conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              &nbsp;·&nbsp;
              <a
                href="/privacy-policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              &nbsp;·&nbsp;
              <a
                href="/cancellations-and-refunds-policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cancellations and Refunds Policy
              </a>
            </p>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export const LandingPage = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPageRender />} />
      </Routes>
    </BrowserRouter>
  );
};
